<template>
    <div class="couponPage">
        <div class="tab-content">
            <van-loading v-if="!show && !loading && !finished" type="spinner" vertical>加载中...</van-loading>
            <Empty v-if="list.length == 0 && !loading && finished" description="暂无数据" />
            <List
                v-model="loading"
                :finished="finished"
                :finished-text="list.length > 0 ? '没有更多了' : ''"
                :offset="30"
                @load="getCouponList"
                v-if="show"
            >
                <div v-for="(item, index) in list" :key="index" class="coupon-item" :class="{'discount':item.coupon.type == 1,'maintain':item.coupon.type == 2,'boutique':item.coupon.type == 3}" @click="couponReceive(item)">
                    <div class="cover" v-show="item.status != 1"></div>
                    <div class="left">
                        <div v-if="item.coupon.cover_img" class="bg" :style="{'background-image': 'url('+item.coupon.cover_img+')'}"></div>
                        <div>
                            <span>{{ item.coupon.type_text }}</span>
                            <div class="btn">{{ statusText[item.status] }}</div>
                        </div>
                    </div>
                    <div class="right">
                        <div class="name">{{ item.coupon.coupon_name }}</div>
                        <div class="time">{{ item.expire_time }} 前有效</div>
                    </div>
                </div>
            </List>
        </div> 
        <!-- <a-tabs default-active-key="1" :tab-bar-gutter="0">
            <a-tab-pane key="1" tab="可用优惠券">
                t
            </a-ab-pane>
            <a-tab-pane key="2" tab="已用" force-render>
                Content of Tab Pane 2
            </a-tab-pane>
        </a-tabs> -->
        <van-dialog v-model="showCode" confirm-button-text="完成核销" confirm-button-color="#1890ff" @confirm="refresh">
            <div class="code">
                <div class="intro">
                    <h4>{{ setData.coupon.coupon_name }}【{{ setData.coupon.type_text }}】</h4>
                    <div>有效使用日期：{{ setData.coupon.use_start_time }} 至 {{ setData.coupon.use_end_time }}</div>
                </div>
                <div id="qrCode" ref="qrCode"></div>
                <div class="tips">请向工作人员出示二维码</div>
                <div class="code-intro" v-if="setData.coupon.desc" v-html="setData.coupon.desc"></div>
            </div> 
        </van-dialog>
    </div>
</template>
<script>
import Vue from "vue"
import { couponIndexApi, couponMyShowApi } from "@/api/coupon"
import { mapActions, mapGetters } from 'vuex'
import { List, Toast, Dialog, Empty, Loading  } from 'vant'
import QRCode from 'qrcodejs2'
Vue.use(List)
// Vue.use(Tab)
// Vue.use(Tabs)
Vue.use(Toast)
Vue.use(Dialog)
Vue.use(Empty)
Vue.use(Loading )

export default {
    components: {
        List,
        // Tab,
        // Tabs,
        Toast,
        Empty,
        Dialog: Dialog.Component,
        Loading
    },
    data () {
        return {
            url: window.location.href,
            list: [],
            loading: false,
            finished: false,
            showCode: false,
            page: 1,
            setData: {
                coupon: {}
            },
            show: false,
            statusText: {
                1: '点击使用',
                2: '已使用',
                3: '已过期',
                4: '已失效'
            }
        }
    },
    created () {
        this.cid = this.$route.query.cid
        this.getOpenUserInfo()
        // this.getCouponList()
    },
    mounted () {
    },
    methods: {
        ...mapActions(['Login']),
        refresh () {
            this.show = false
            this.list = []
            this.page = 1
            this.finished = false
            this.getCouponList() 
        },
        getOpenUserInfo () {
            this.getCouponList()
            // let that = this;
            // that.Login(that.cid).then((res) => {
            //     if (!res.data.token) {
            //         let redirectUrl = '/auth/officialAccount?cid='+that.cid+'&check_code=591060&module=officialAccount&target=' + encodeURIComponent(that.url);
            //         that.$redirectAuth(redirectUrl);
            //     }
            //     this.getCouponList()
            // }).catch(res => {
            //     let redirectUrl = '/auth/officialAccount?cid='+that.cid+'&check_code=591060&module=officialAccount&target=' + encodeURIComponent(that.url);
            //     that.$redirectAuth(redirectUrl);
            // })
        },
        getCouponList () {
            if(!this.finished){
                couponIndexApi({page: this.page}).then(res => {
                    if(res.code == 200){
                        let arr = res.data.lists.data
                        // 检查优惠券的有效期
                        for(var i = 0; i < arr.length; i++){
                            if(Date.parse(arr[i].expire_time) < Date.parse(new Date()) && arr[i].status == 1){
                                arr[i].status = 3
                            }
                        }
                        this.list = this.list.concat(arr)
                        this.show  = true
                    }
                    // 加载状态结束
                    this.loading = false;
                    // 数据全部加载完成
                    if (!res.data.lists.next_page_url) {
                        this.finished = true;
                    } else {
                        this.page++
                    }
                }).catch(() => {
                    this.loading = false;
                    // 数据全部加载完成
                    this.finished = true;
                })
            }
            
        },
        couponReceive (data) {
            if(data.status == 1){
                couponMyShowApi({id: data.id}).then(res => {
                    if(res.code == 200){
                        this.setData = res.data
                        this.showCode = true
                        setTimeout(() => {
                            this.$refs.qrCode.innerHTML = ''
                            new QRCode(this.$refs.qrCode, {
                                text: JSON.stringify({
                                type: 'coupon',
                                value: this.setData.write_off_code
                                }),
                                width: 200,
                                height: 200,
                            })
                        },400);
                    }else{
                      Toast(res.msg)  
                    }
                })
                
            }
        }
    }
}
</script>
<style lang="less" scoped>
.van-dialog__footer {
    box-shadow: 0px 0px 8px rgba(0,0,0,.1);
}

.couponPage {
    // background: #f6f6f6;
    .ant-tabs-nav {
        background: #fff;
        width: 100%;
        display: flex;
        >div {
            width: 100%;  
        }
        .ant-tabs-tab {
            flex: 1;
            width: 35%;
            text-align: center;
            margin: 0 10%;
        }
        .ant-tabs-ink-bar {
            // width: 30% !important;
        }
    }

    .code {
        padding: 30px 10px 20px;
        text-align: center;
        overflow-y: auto;
        max-height: 68vh;

        #qrCode {
            display: inline-block;
            height: 200px;
            margin-bottom: 6px;
        }

        .tips {
            font-size: 12px;
            margin-bottom: 30px;
        }

        .intro {
            margin-bottom: 20px;
            h4 {
                color: #1890ff;
                margin-bottom: 10px
            }

            div {
                color: #999;
                font-size: 12px;
            }
        }

        &-intro {
            img {
                max-width: 100%;
            }
            padding: 10px;
            font-size: 14px;
            text-align: left;
            border-radius: 10px;
            background-color: #f6f6f6;
        }
    }

    .tab-content {
        padding: 20px 1.15rem;
    }
    .coupon-item {
        display: flex;
        overflow: hidden;
        margin-bottom: 20px;
        height: 100px;
        position: relative;
        border-radius: 4px;

        .cover {
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            z-index: 2;
            // background: #000;
            background: 
            radial-gradient(circle at right top, transparent 6px, #000 0) top left / 100px 50% no-repeat, 
            radial-gradient(circle at right bottom, transparent 6px, #000 0) bottom left /100px 51% no-repeat, 
            radial-gradient(circle at left top, transparent 6px, #000 0) top right / calc(100% - 100px) 51% no-repeat, 
            radial-gradient(circle at left bottom, transparent 6px, #000 0) bottom right / calc(100% - 100px) 51% no-repeat;
            filter: drop-shadow(0px 0px 3px rgba(0, 0, 0, .2));
            opacity: .4;
        }

        .left {
            position: relative;
            width: 100px;
            font-size: 18px;
            text-shadow: 1px 1px rgba(0, 0, 0, .3);
            padding: 15px;
            color: #fff;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;

            .btn {
                margin-top: 6px;
                font-size: 12px;
                color: #f6f6f6;
            }

            .bg {
                position: absolute;
                border-radius: 4px 16px 16px 4px;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
                z-index: -1;
                opacity: .4;
                background-size: cover;
                background-position: center center;
            }
        }

        .right {
            position: relative;
            flex: 1;
            padding: 1rem 1.2rem;
            color: #fff;

            .name {
                font-size: 16px;
            }

            .time {
                position: absolute;
                bottom: 20px;
                font-size: 12px;
                color: #f6f6f6;
            }
        }
    }
    
    .coupon-item::before {
        content: '';
        height: 80px;
        width: 0;
        border: 1px dashed #fff;
        position: absolute;
        left: 99px;
        top: 0;
        bottom: 0;
        margin: auto;
    }

    .coupon-item.discount {
        background: 
            radial-gradient(circle at right top, transparent 6px, #fa526b 0, #ff6a87 90%) top left / 100px 50% no-repeat, 
            radial-gradient(circle at right bottom, transparent 6px, #fa526b 0, #ff6a87 90%) bottom left /100px 51% no-repeat, 
            radial-gradient(circle at left top, transparent 6px, #fa526b 0, #ff6a87 90%) top right / calc(100% - 100px) 51% no-repeat, 
            radial-gradient(circle at left bottom, transparent 6px, #fa526b 0, #ff6a87 90%) bottom right / calc(100% - 100px) 51% no-repeat;
        filter: drop-shadow(0px 0px 3px rgba(0, 0, 0, .2));
    }

    .coupon-item.maintain {
        background: 
            radial-gradient(circle at right top, transparent 6px, #1890ff 0, #2b99fe 90%) top left / 100px 50% no-repeat, 
            radial-gradient(circle at right bottom, transparent 6px, #1890ff 0, #2b99fe 90%) bottom left /100px 51% no-repeat, 
            radial-gradient(circle at left top, transparent 6px, #1890ff 0, #2b99fe 90%) top right / calc(100% - 100px) 51% no-repeat, 
            radial-gradient(circle at left bottom, transparent 6px, #1890ff 0, #2b99fe 90%) bottom right / calc(100% - 100px) 51% no-repeat;
        filter: drop-shadow(0px 0px 3px rgba(0, 0, 0, .2));
    }

    .coupon-item.boutique {
        background: 
            radial-gradient(circle at right top, transparent 6px, #fe9400 0, #fdb000 90%) top left / 100px 50% no-repeat, 
            radial-gradient(circle at right bottom, transparent 6px, #fe9400 0, #fdb000 90%) bottom left /100px 51% no-repeat, 
            radial-gradient(circle at left top, transparent 6px, #fe9400 0, #fdb000 90%) top right / calc(100% - 100px) 51% no-repeat, 
            radial-gradient(circle at left bottom, transparent 6px, #fe9400 0, #fdb000 90%) bottom right / calc(100% - 100px) 51% no-repeat;
        filter: drop-shadow(0px 0px 3px rgba(0, 0, 0, .2));
    }

    // .coupon-item {
    //     position: relative;
    //     margin-bottom: 20px;
    //     position: relative;
    //     height: 100px;
    //     text-align: -webkit-auto;
    //     background-image: radial-gradient(
    //         circle at 0px 5px,
    //         transparent 5px,
    //         #1890ff 4px,
    //         #1890ff 0px
    //         ),
    //         radial-gradient(
    //         circle at 0 8px,
    //         transparent 5px,
    //         #1890ff 5px,
    //         #1890ff 0px
    //         );
    //     background-size: 100% 16px;
    //     background-position: 0 0, 100% 0;
    //     background-repeat-x: no-repeat;
    //     font-size: 60px;
    //     color: #fff;
    //     font-weight: bold;
    //     line-height: 120px;
    //     padding-left: 60px;
    //     box-sizing: border-box;
    //     cursor: pointer;
    //     border-radius: 0 10px 10px 0;
    //     box-shadow: 2px 2px 8px rgba(0, 0, 0, .2);
    // }
    // .coupon-item::before {
    //     position: absolute;
    //     content: "";
    //     left: 70%;
    //     top: 0;
    //     bottom: 0;
    //     width: 0;
    //     border-left: 1px dashed #fff;
    // }
}
</style>
