import {request, request_check} from "../plugins/axios";

// 领取优惠券
export function couponReceiveApi (params) {
    return request({
        url: '/coupon/receive',
        method: 'GET',
        params: params
    })
}

// 优惠券详情
export function couponShowApi (params) {
    return request({
        url: '/coupon/show',
        method: 'GET',
        params
    })
}

// 我的优惠券
export function couponIndexApi (params) {
    return request_check({
        url: '/coupon/index',
        method: 'GET',
        params
    })
}

// 我的优惠券
export function couponMyShowApi (params) {
    return request_check({
        url: '/coupon/myShow',
        method: 'GET',
        params
    })
}

// 支付优惠券

export function payCoupon (params) {
    return request({
        url: '/coupon/buy',
        method: 'GET',
        params
    })
}

// 下单优惠券
export function buyCoupon (params) {
    console.log(params)
    return request({
        url: '/coupon/buy',
        method: 'GET',
        params
    })
}